import { Component } from '@angular/core';
import { SessionService } from 'core/session.service';

@Component({
    selector: 'app-cookie-popup',
    templateUrl: './cookie-popup.component.html',
    styleUrls: ['./cookie-popup.component.scss'],
})
export class CookiePopupComponent {
    showPopup: boolean = true;

    constructor(private sessionService: SessionService) {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (cookiesAccepted) {
            this.showPopup = false;
        }
    }

    acceptCookies(): void {
        this.sessionService.setItem('cookiesAccepted', 'false');
        this.showPopup = false;
    }
}
